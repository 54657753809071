import PropTypes from 'prop-types';

import { Stack, Typography, Divider } from '@mui/material';
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';

DialogTitleComponent.propTypes = {
   icon: PropTypes.string,
   title: PropTypes.string,
   onClose: PropTypes.func,
};

export default function DialogTitleComponent({ icon, title, onClose }) {
   return (
      <Stack>
         <Stack flexDirection={'row'} columnGap={2} alignItems={'center'} my={2} mx={1}>
            <Iconify icon={icon} flexGrow={0} width={24} height={24} ml={2} />
            <Typography variant={'h6'} sx={{ flexGrow: 1 }}>
               {title}
            </Typography>
            <IconButtonAnimate onClick={onClose}>
               <Iconify icon={'material-symbols:close'} cursor={'pointer'} flexGrow={0} />
            </IconButtonAnimate>
         </Stack>
         <Divider />
      </Stack>
   );
}
