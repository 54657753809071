import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
   const { login } = useAuth();

   const isMountedRef = useIsMountedRef();

   const [showPassword, setShowPassword] = useState(false);

   const LoginSchema = Yup.object().shape({
      email: Yup.string().required('Usuário é obrigatório'),
      password: Yup.string().required('Senha é obrigatório'),
   });

   const defaultValues = {
      email: '',
      password: '',
      remember: true,
   };

   const methods = useForm({
      resolver: yupResolver(LoginSchema),
      defaultValues,
   });

   const {
      reset,
      setError,
      handleSubmit,
      formState: { errors, isSubmitting },
   } = methods;

   const onSubmit = async (data) => {
      try {
         await login(data.email, data.password);
      } catch (error) {
         console.error(error);

         reset();

         if (isMountedRef.current) {
            setError('afterSubmit', { ...error, message: error.message || error });
         }
      }
   };

   return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
         <Stack spacing={3}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <RHFTextField name="email" label="Usuário" />

            <RHFTextField
               name="password"
               label="Senha"
               type={showPassword ? 'text' : 'password'}
               InputProps={{
                  endAdornment: (
                     <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                           <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />
         </Stack>

         <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <RHFCheckbox name="remember" label="Lembrar" />
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
               Esqueceu sua senha?
            </Link>
         </Stack>

         <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
         </LoadingButton>
      </FormProvider>
   );
}
