import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';

// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, product, title, description, meta, cover, ...other }, ref) => (
   <>
      <Helmet>
         <title>{title}</title>

         {/* <meta property="og:title" content={'title'} />
         <meta property="og:type" content="article" />
         <meta property="og:description" content={'product?.description'} />
         <meta property="og:image" content={'product?.imageUrls[0]'} />
         <meta property="og:url" content={'window.location.href'} />
         <meta name="twitter:card" content="summary_large_image" /> */}

         {/* <meta property="og:type" content="website" />
         <meta property="og:title" content={product.title} />
         <meta property="og:description" content={product.description} />
         <meta property="og:image" content={product.imageUrls[0]} /> */}
         {/* {meta} */}
      </Helmet>

      <Box ref={ref} {...other}>
         {children}
      </Box>
   </>
));

Page.propTypes = {
   children: PropTypes.node.isRequired,
   product: PropTypes.object,
   title: PropTypes.string,
   description: PropTypes.string,
   meta: PropTypes.node,
   cover: PropTypes.string,
};

export default Page;
