import { useEffect } from 'react';
// analytics
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

// browser
import { createBrowserHistory } from 'history';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { APP_VERSION } from './config';
// utils
import JivoChat from './components/JivoChatWidget';
import { useSelector } from './redux/store';

// ----------------------------------------------------------------------

export default function App() {

   console.log(`v`, APP_VERSION);

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const { settings } = useSelector((state) => state);

   useEffect(() => {
      // eslint-disable-next-line consistent-return
      const initializeAnalytics = async () => {
         if (settings && settings.settings) {
            const analytics = settings?.settings?.analytics;

            if (!analytics) return;

            const { pixel, pixelToken, google: googleGA } = analytics;

            // se for apenas pixel
            if (pixel) {
               ReactPixel.init(pixel);
               ReactPixel.pageView();
            }
            // // se for facebook conversion
            // if (pixelToken && pixel) {
            //    const event = 'pageView';
            //    const customData = {
            //       content_name: document.title || 'homePage',
            //    };
            //    await sendEventToFacebook(settings, customData, event);
            // }

            // Initialize Google Analytics
            if (googleGA) {
               ReactGA.initialize(googleGA);
               // Create a history object to use with React Router
               const history = createBrowserHistory();

               // Set up a listener for route changes
               const unlisten = history.listen((location) => {
                  // Send pageview on each route change
                  ReactGA.send({ hitType: 'pageview', page: location.pathname });
               });

               // eslint-disable-next-line consistent-return
               return () => {
                  // Clean up the listener when the component unmounts
                  unlisten();
               };
            }
         }
      };

      // Chamar a função assíncrona
      if (settings) {
         initializeAnalytics();
      }

   }, [settings])

   return (
      <MotionLazyContainer>
         <ThemeProvider>
            <ThemeSettings>
               <NotistackProvider>
                  <JivoChat />
                  <ProgressBarStyle />
                  <ChartStyle />
                  <ScrollToTop />
                  <Router />
               </NotistackProvider>
            </ThemeSettings>
         </ThemeProvider>
      </MotionLazyContainer>
   );
}
